<template>
  <vca-card>
    <h2>{{ $t("email.list.header") }}</h2>
    <div v-if="mailLength > 0">
      <div class="desktop-options">
        <button @click="navigate()" class="vca-button-small text-left">
          {{ $t("navigation.emails.write") }}
        </button>
      </div>
      <vca-tabs class="white-tab">
        <vca-tab :title="$t('navigation.emails.drafts')" :selected="true">
          <vca-card class="shadowed vca-center">
            <AspEmailTable mailbox="draft" />
          </vca-card>
          <vca-popup
            v-if="current != null"
            :show="current != null"
            :title="$t('email.popup.view')"
            @close="current = null"
          >
            <EmailPreview
              v-model="current"
              :event="currentEvent"
              :preview="false"
              :deleteable="true"
              :sendable="true"
              @delete="remove"
              @send="send"
            />
          </vca-popup>
        </vca-tab>
        <vca-tab :title="$t('navigation.emails.outbox')">
          <vca-card class="shadowed vca-center">
            <AspEmailTable mailbox="outbox" />
          </vca-card>
          <vca-popup
            v-if="current != null"
            :show="current != null"
            :title="$t('email.popup.view')"
            @close="current = null"
          >
            <EmailPreview
              v-model="current"
              :event="currentEvent"
              :preview="false"
              :deleteable="false"
              :sendable="false"
            />
          </vca-popup>
        </vca-tab>
      </vca-tabs>
    </div>
    <vca-card v-else>
      <vca-card class="text-center shadowed">
        <h2>{{ $t("email.event.no_events") }}</h2>
      </vca-card>
    </vca-card>
  </vca-card>
</template>

<script>
import AspEmailTable from "@/components/account/emails/AccountAspEmailsTable";
import EmailPreview from "@/components/emails/EmailPreview";
import { mapGetters } from "vuex";
export default {
  name: "AccountEmails",
  components: { AspEmailTable, EmailPreview },
  data() {
    return {
      mailLength: 0,
    };
  },
  computed: {
    currentEvent() {
      const event = this.getEvent(this.current.recipient_group.event_id);
      return event;
    },
    ...mapGetters({
      getEvent: "user/aspevents/getEvent",
      hasSystemPermission: "user/roles/hasSystemPermission",
      user: "user/current",
    }),
    current: {
      get() {
        return this.$store.state.user.emails.mailbox.message.current;
      },
      set(value) {
        this.$store.commit("user/emails/mailbox/message/current", value);
      },
    },
  },
  methods: {
    navigate() {
      this.$router.push({ path: "/account/asps/emails/create" });
    },
    init() {
      this.$store.dispatch("user/emails/mailbox/get").then((response) => {
        this.$store.commit(
          "user/emails/mailbox/draft_pg/pageSize",
          response["draft"].length
        );
        this.$store.commit(
          "user/emails/mailbox/outbox_pg/pageSize",
          response["outbox"].length
        );
        this.$store.commit(
          "user/emails/mailbox/inbox_pg/pageSize",
          response["inbox"].length
        );
        this.mailLength =
          response["draft"].length +
          response["outbox"].length +
          response["inbox"].length;
      });
    },
    remove() {
      this.$store.dispatch("user/emails/mailbox/message/delete").then(() => {
        this.$store.dispatch("user/emails/mailbox/get");
      });
    },
    send() {
      this.$store.commit("suppressMsg", true);
      this.$store
        .dispatch("user/emails/mailbox/message/update")
        .then((response) => {
          this.$store.commit("suppressMsg", false);
          this.$store.commit("user/reset_message");
          this.$store.commit("user/emails/mailbox/message/current", response);
          this.$store.dispatch("user/emails/mailbox/message/send").then(() => {
            this.$store.dispatch("user/emails/mailbox/get").then(() => {
              this.current = null;
            });
          });
        });
    },
  },
  created() {
    this.current = null;
    var id = { mailbox_id: this.user.crew.mailbox_id };
    this.$store.commit("user/emails/mailbox/id", id);

    this.$store.dispatch({ type: "events/listAsp" }).then((response) => {
      this.eventList =
        !response || response.length == 0
          ? []
          : response.filter((el) => {
              el.is_event_asp = true;
              return true;
            });
      if (!this.hasSystemPermission()) {
        this.$store.commit("user/aspevents/list", this.eventList);
        this.$store.commit("user/aspevents/pg/pageSize", this.eventList.length);
        this.init();
      } else if (!this.hasSystemPermission()) {
        let data = {
          internal_asp_id: this.user.id,
        };
        this.$store
          .dispatch({ type: "events/queryList", data: data })
          .then((response) => {
            const internalList =
              !response || response.length == 0 ? [] : response;

            let all = [...this.eventList];
            Array.prototype.push.apply(all, internalList);

            let result = [];
            all.forEach((el) => {
              el.is_event_asp = this.eventList.some(
                (entry) => entry.id == el.id
              );
              el.is_internal_asp = internalList.some(
                (entry) => entry.id == el.id
              );
              if (!result.some((entry) => entry.id == el.id)) {
                result.push(el);
              }
            });
            this.$store.commit("user/aspevents/list", result);
            this.$store.commit("user/aspevents/pg/pageSize", result.length);
            this.init();
          });
      } else {
        this.$store
          .dispatch({ type: "events/queryList", data: {} })
          .then((response) => {
            const internalList =
              !response || response.length == 0 ? [] : response;

            let all = [...this.eventList];
            Array.prototype.push.apply(all, internalList);

            let result = [];
            all.forEach((el) => {
              el.is_event_asp = this.eventList.some(
                (entry) => entry.id == el.id
              );
              el.is_internal_asp = internalList.some(
                (entry) => entry.id == el.id
              );
              if (!result.some((entry) => entry.id == el.id)) {
                result.push(el);
              }
            });
            this.$store.commit("user/aspevents/list", result);
            this.$store.commit("user/aspevents/pg/pageSize", result.length);
            this.init();
          });
      }
    });
    this.$store.dispatch("crews/public");
  },
};
</script>
<style lang="scss">
.white-tab {
  .is-active .link {
    color: white !important;
  }
}
</style>
